<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Abril 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <section class="d-flex">
      <!-- Filtrar por Nombre -->
      <v-text-field class="me-2 filtro" v-model="buscar.nombre" label="Nombre" outlined dense
        hide-details color="primary">
      </v-text-field>

      <!-- Filtrar por Tipo -->
      <v-autocomplete class="me-2 filtro" v-model="buscar.tipo" label="Tipo" outlined dense
        hide-details color="primary" :items="competencias" :menu-props="{ offsetY: true }">
      </v-autocomplete>

      <!-- Filtrar por estado -->
      <v-autocomplete class="filtro me-2" v-model="buscar.estado" label="Estado" outlined dense
        hide-details color="primary" :items="estados" :menu-props="{ offsetY: true }">
      </v-autocomplete>

      <!-- Este botón abre un diálogo para agregar una competencia-->
      <v-tooltip color="success" :open-on-focus="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab small color="success" v-on="on" v-bind="attrs"
            @mousedown.prevent="dialogoCompetencia = true">
            <v-icon>add</v-icon>
          </v-btn>
        </template>
        <span>Agregar</span>
      </v-tooltip>
    </section>

    <!-- Diálogo para crear/editar una competencia -->
    <v-dialog v-model="dialogoCompetencia" transition="dialog-bottom-transition" max-width="40rem" persistent :editar="false">
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ tituloDialogo }} </span>
        </v-card-title>
        <v-card-text class="pt-5 size-card">
          <!-- Formulario para registrar una competencia -->
          <v-form ref="competenciaForm">
            <div class="d-flex">
              <!-- Campo para el nombre -->
              <v-text-field class="me-2 filtro" v-model="crearCompetencia.nombre" label="Nombre" dense outlined
                :rules="rules.required" color="primary">
              </v-text-field>

              <!-- Campo para el tipo -->
              <v-select class="filtro" v-model="crearCompetencia.tipo" label="Tipo" required dense outlined
                :rules="rules.required" :items="competencias" :menu-props="{ offsetY: true }" color="primary">
              </v-select>
            </div>

            <!-- Campo para la descripción general -->
            <v-text-field v-model="crearCompetencia.descripcion" label="Descripción" dense outlined
              :rules="rules.required" color="primary">
            </v-text-field>

            <span class="text-h6 descriptor">Descriptores</span>
            <div class="linea"></div>

            <div class="mt-2">
              <span class="ms-1">Nivel</span> <span class="ms-3">Descripción</span>
            </div>

            <div v-for="(descriptor, index) in crearCompetencia.descriptores" :key="index" class="d-flex mt-3">
              <span class="me-2 nivel">{{ descriptor.nivel }}</span>
              <v-text-field v-model="descriptor.descripcion" dense outlined :rules="rules.required"
                color="primary">
              </v-text-field>
            </div>

            <!-- Botones del formulario -->
            <div class="d-flex justify-end">
              <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
              <v-btn :color="!formValid ? 'grey' : 'success'" :disabled="!formValid" depressed
                @click="!editar ? agregarCompetencia() : editarCompetencia()">Guardar</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- cards que contienen la información de las competencias -->
    <v-container class="contenedor-cards  ma-0 pa-0 pt-4">
      <v-row class="ma-0">
        <v-col v-if="cardCompetencia.items.length > 0" class="pa-0">
          <v-card class="mb-1 grey lighten-2">
            <v-card-text class="font-weight-black d-flex align-center black--text">
              <v-row class="pe-4">
                <v-col cols="12" md="2" class="d-flex justify-center pa-0">COMPETENCIA</v-col>
                <v-col cols="12" md="6" class="d-flex justify-center pa-0">DESCRIPCIÓN</v-col>
                <v-col cols="12" md="2" class="d-flex justify-center pa-0">DESCRIPTORES</v-col>
                <v-col cols="12" md="2" class="d-flex justify-center pa-0">ACCIONES</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="cardCompetencia.items.length > 0" cols="12" md="12" class="pa-0 contenido-cards">
          <v-card border class="mb-1 elevation-2" v-for="item in cardCompetencia.items" :key="item.ano">
            <v-card-text style="color:black">
              <v-row>
                <!-- Primer columna con la información general -->
                <v-col cols="12" md="2" class="d-flex align-center">
                  <v-row class="ps-2">
                    <v-col cols="12" md="12" class="pa-1">
                      <span class="info-card">Nombre: </span>{{ item.nombre }}
                    </v-col>
                    <v-col cols="12" md="12" class="pa-1">
                      <span class="info-card">Tipo: </span> {{ obtenerTipo(item.tipoCompetenciaValue) }}
                    </v-col>
                    <v-col cols="12" md="12" class="pa-1">
                      <span class="info-card">Estado: </span>
                      <span class="font-weight-black" :class="item.eliminado === false ? 'success--text' : 'error--text'">
                        {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- Segunda columna con la descripción -->
                <v-col cols="12" md="6" class="d-flex align-center" align="center">
                  <v-row class="pe-2">
                    <v-col cols="12" md="12" class="pa-0"> {{ item.descripcion }} </v-col>
                  </v-row>
                </v-col>

                <!-- Tercera columna de los descriptores -->
                <v-col cols="12" md="2" class="d-flex align-center" align="center">
                  <v-row>
                    <v-col cols="12" md="12" class="pa-0">
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon fab small color="primary" v-on="on" v-bind="attrs">
                            <v-icon @click="abrirDescriptores(item)">visibility</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver descriptores</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- Cuarta columna de las acciones -->
                <v-col cols="12" md="2" class="d-flex align-center" align="center">
                  <v-row>
                    <v-col cols="12" md="12" class="pa-0">
                      <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'grey'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="ms-2 me-2" icon fab small v-on="on" v-bind="attrs" :color="item.eliminado === false ? 'orange' : 'grey'">
                            <v-icon @click="item.eliminado === false && abrirDialogoEditar(item)">border_color</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ item.eliminado === false ? 'Editar' : 'Competencia inactiva' }}</span>
                      </v-tooltip>

                      <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'success'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="ms-2 me-2" icon fab small v-on="on" v-bind="attrs"
                            :color="item.eliminado === false ? 'error' : 'success'">
                            <v-icon @click="abrirDialogoEstado(item)">{{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12" md="12" class="mt-4 pa-0 d-flex justify-center">
          <span class="text-grey">No se encontraron resultados.</span>
        </v-col>
      </v-row>

      <!-- Paginado -->
      <v-row v-if="cardCompetencia.items.length > 0" class="paginado">
        <span class="me-8">Items por página:</span>
        <div>
          <v-select class="me-6 select" v-model="cardCompetencia.itemsPerPage" hide-details dense
            :items="cardCompetencia.opciones">
          </v-select>
        </div>

        <span class="items"> {{ rangoItems }} de {{ cardCompetencia.totalElements }}</span>
        <v-btn icon fab small :disabled="cardCompetencia.page === 1" @click="cardCompetencia.page = 1">
          <v-icon medium>first_page</v-icon>
        </v-btn>
        <v-btn icon fab small :disabled="cardCompetencia.page === 1"
          @click="cardCompetencia.page = cardCompetencia.page - 1">
          <v-icon medium>chevron_left</v-icon>
        </v-btn>
        {{ cardCompetencia.page }}
        <v-btn icon fab small :disabled="cardCompetencia.page === cardCompetencia.totalPage"
          @click="cardCompetencia.page = cardCompetencia.page + 1">
          <v-icon medium>chevron_right</v-icon>
        </v-btn>
        <v-btn icon fab small :disabled="cardCompetencia.page === cardCompetencia.totalPage"
          @click="cardCompetencia.page = cardCompetencia.totalPage">
          <v-icon medium>last_page</v-icon>
        </v-btn>
      </v-row>
    </v-container>

    <!-- Diálogo para ver los niveles descriptores de la competencia -->
    <v-dialog v-model="dialogoDescriptores" transition="dialog-bottom-transition" max-width="45rem" persistent>
      <v-card>
        <v-card-title class="justify-center encabezado pt-3">
          <span class="text-h6"> Descriptores </span>
        </v-card-title>
        <v-card-text class="pt-5 size-card">
          <div class="d-flex mb-2" v-for="descriptor in competenciaSeleccionada.competenciaDescriptor" :key="descriptor.nivel">
            <div class=" d-flex align-center">Nivel {{ descriptor.nivel }}:</div>
            <v-card border class="descriptores elevation-2">{{ descriptor.descripcion }}</v-card>
          </div>
        </v-card-text>
        <!-- Botón de cerrar -->
        <v-card-actions class="d-flex justify-end ps-6 pe-6 pb-5 pt-0">
          <v-btn text color="error" @click="clear()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialogo para cambiar el estado -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ mensajeEstado }} </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
            <v-btn color="success" depressed @click="cambiarEstado()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";

export default {
  name: "Competencia",
  data() {
    return {
      buscar: {
        nombre: '',
        tipo: null,
        estado: null,
      },
      estados: [
        { text: 'Activo', value: false },
        { text: 'Inactivo', value: true }
      ],
      competencias: [
        { text: 'Organizacional', value: 'O' },
        { text: 'Individual', value: 'I' }
      ],
      tituloDialogo: 'Agregar',
      dialogoCompetencia: false,
      crearCompetencia: {
        nombre: '',
        descripcion: '',
        tipo: null,
        descriptores: [
          { nivel: 1, descripcion: '' },
          { nivel: 2, descripcion: '' },
          { nivel: 3, descripcion: '' },
          { nivel: 4, descripcion: '' }
        ]
      },
      cardCompetencia: {
        items: [],
        opciones: [10, 20, 30],
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      },
      rules: {
        required: [
          v => !!v || "Este campo es requerido.",
        ],
      },
      editar: false,
      dialogoDescriptores: false,
      competenciaSeleccionada: {
        competenciaDescriptor: []
      },
      usuario: '',
      dialogoCambiarEstado: false,
      mensajeEstado : '',
    }
  },
  mounted() {
    this.listarCompetencias();
    this.usuario = this.auth.username.toLowerCase();
  },
  watch: {
    'cardCompetencia.page': function () {
      this.listarCompetencias();
    },
    'cardCompetencia.itemsPerPage': function () {
      this.cardCompetencia.page = 1;
      this.listarCompetencias();
    },
    'buscar.nombre': function () {
      this.cardCompetencia.page = 1;
      this.listarCompetencias();
    },
    'buscar.tipo': function () {
      this.cardCompetencia.page = 1;
      this.listarCompetencias();
    },
    'buscar.estado': function () {
      this.cardCompetencia.page = 1;
      this.listarCompetencias();
    },
  },
  computed: {
    ...mapState(["auth", "enterprise"]),
    formValid() {
      const { nombre, descripcion, tipo, descriptores } = this.crearCompetencia;
      return nombre && descripcion && tipo && descriptores.every(descriptor => descriptor.descripcion);
    },
    rangoItems() {
      const start = (this.cardCompetencia.page - 1) * this.cardCompetencia.itemsPerPage + 1;
      let end = this.cardCompetencia.page * this.cardCompetencia.itemsPerPage;
      if (end > this.cardCompetencia.totalElements) {
        end = this.cardCompetencia.totalElements;
      }
      return `${start} - ${end}`;
    },
  },
  methods: {
    listarCompetencias() {
      this.$http.get(`msa-hr/api/competencia/listar`, {
        params: {
          page: this.cardCompetencia.page - 1,
          size: this.cardCompetencia.itemsPerPage,
          nombre: this.buscar.nombre,
          tipoCompetenciaValue: this.buscar.tipo,
          idEmpresa: this.enterprise.code,
          eliminado: this.buscar.estado
        }
      }).then((response) => {
        this.cardCompetencia.items = response.data.content;
        this.cardCompetencia.totalPage = response.data.totalPages;
        this.cardCompetencia.totalElements = response.data.totalElements;
      }).catch((error) => {
        console.log(error);
      });
    },
    agregarCompetencia() {
      const competencia = {
        idEmpresa: this.enterprise.code,
        nombre: this.crearCompetencia.nombre,
        descripcion: this.crearCompetencia.descripcion,
        tipoCompetenciaValue: this.crearCompetencia.tipo,
        eliminado: false,
        createdby: this.usuario,
        lastmodifiedby: this.usuario,
        competenciaDescriptor: this.crearCompetencia.descriptores.map(descriptor => ({
          nivel: descriptor.nivel,
          descripcion: descriptor.descripcion
        }))
      }
      this.$http.post(`msa-hr/api/competencia/guardar`, competencia)
        .then(() => {
          this.listarCompetencias();
          this.dialogoCompetencia = false;
          this.clear();
        }).catch((error) => {
          console.log(error);
        });
    },
    abrirDescriptores(item) {
      this.dialogoDescriptores = true;
      this.competenciaSeleccionada = item;
    },
    abrirDialogoEditar(item) {
      this.tituloDialogo = 'Editar';
      this.editar = true;
      this.dialogoCompetencia = true;
      const descriptores = item.competenciaDescriptor.map(descriptor => ({
        nivel: descriptor.nivel,
        descripcion: descriptor.descripcion
      }));
      this.crearCompetencia = {
        ...item,
        descriptores
      };
      this.crearCompetencia.tipo = item.tipoCompetenciaValue;
      this.competenciaSeleccionada = item;
    },
    editarCompetencia() {
      const competencia = {
        idCompetencia: this.competenciaSeleccionada.idCompetencia,
        idEmpresa: this.enterprise.code,
        nombre: this.crearCompetencia.nombre,
        descripcion: this.crearCompetencia.descripcion,
        tipoCompetenciaValue: this.crearCompetencia.tipo,
        eliminado: false,
        lastmodifiedby: this.usuario,
        competenciaDescriptor: this.crearCompetencia.descriptores.map(descriptor => ({
          nivel: descriptor.nivel,
          descripcion: descriptor.descripcion
        }))
      }
      this.$http.post(`msa-hr/api/competencia/guardar`, competencia)
        .then(() => {
          this.listarCompetencias();
          this.dialogoCompetencia = false;
          this.clear();
        }).catch((error) => {
          console.log(error);
        });
    },
    abrirDialogoEstado(item) {
      this.dialogoCambiarEstado = true;
      this.competenciaSeleccionada = item;
      this.mensajeEstado = this.competenciaSeleccionada.eliminado === false ? '¿Desea inactivar la competencia?' : '¿Desea activar la competencia?';
    },
    cambiarEstado() {
      this.competenciaSeleccionada.eliminado = (this.competenciaSeleccionada.eliminado == false) ? true : false;
      const cambiarEstado = {
        idCompetencia: this.competenciaSeleccionada.idCompetencia,
        idEmpresa: this.enterprise.code,
        nombre: this.competenciaSeleccionada.nombre,
        descripcion: this.competenciaSeleccionada.descripcion,
        tipoCompetenciaValue: this.competenciaSeleccionada.tipoCompetenciaValue,
        eliminado: this.competenciaSeleccionada.eliminado,
        lastmodifiedby: this.usuario,
        competenciaDescriptor: this.competenciaSeleccionada.competenciaDescriptor.map(descriptor => ({
          nivel: descriptor.nivel,
          descripcion: descriptor.descripcion
        }))
      }
      this.$http.post(`msa-hr/api/competencia/guardar`, cambiarEstado)
        .then(() => {
          this.listarCompetencias();
          this.dialogoCompetencia = false;
          this.clear();
        }).catch((error) => {
          console.log(error);
        });
    },
    obtenerTipo(item) {
      const tipo = this.competencias.find(competencia => competencia.value === item);
      return tipo ? tipo.text : '';
    },
    clear() {
      this.dialogoDescriptores = false;
      this.dialogoCompetencia = false;
      this.dialogoCambiarEstado = false;
      this.editar = false;
      setTimeout(() => {
        this.tituloDialogo = 'Agregar';
        this.competenciaSeleccionada = {};
        this.crearCompetencia = {
          nombre: '',
          descripcion: '',
          tipo: null,
          descriptores: [
            { nivel: 1, descripcion: '' },
            { nivel: 2, descripcion: '' },
            { nivel: 3, descripcion: '' },
            { nivel: 4, descripcion: '' }
          ]
        };
        if (this.$refs.competenciaForm) {
          this.$refs.competenciaForm.resetValidation();
        }
      }, 600);
    },
  },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 86vh;
}

.filtro {
  width: 33.33%;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

.contenedor-cards {
  width: 100%;
  max-width: none;
}

.contenido-cards {
  max-height: 60vh;
  overflow-y: auto;
  border: solid 0.5px;
  border-color: #e0e0e0;
}

.descriptor {
  color: #0d47a1;
  display: flex;
  justify-content: center;
}

.nivel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.linea {
  height: 1px;
  background-color: #0d47a1;
}

.info-card {
  color: black;
  padding: 3px;
  font-weight: bold;
}

.contenedor-card div {
  color: black;
}

.paginado {
  font-size: small;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding-top: 16px;
}

.paginado button {
  border: none;
  margin: 0 7px;
}

.items {
  margin: 0 28px;
}

.descriptores {
  width: 90%;
  padding: 8px;
  margin-left: 8px;
}

.size-card {
  max-height: 80vh;
  overflow-y: auto;
}

::v-deep .select .v-input__control {
  width: 55px !important;
}
</style>